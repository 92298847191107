/* eslint-disable array-callback-return */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */

export default class ValidReservation {
  constructor(that) {
    this.$vue = that;
    this.Halls = []; // 드래그로 선택된 데이타
    this.EventHalls = this.$vue.eventhalls; // 예약현황 데이타($vue)
    this.SelectedHalls = this.$vue.selectedHalls; // 선택된 데이타($vue)
    this.validStartTime = undefined;
    this.validEndTime = undefined;
    this.validReserveTime = true;
    this.isTenant = undefined;
    this.memberNo = undefined;
    this.isWeekend = undefined;
    this.msgError = '';
    this.init();
  }
}

ValidReservation.prototype.init = function () {
  this.setHalls();
  this.setReserveTime();
  this.setTenant();
  this.setWeekend();
};

ValidReservation.prototype.reset = function () {
  this.Halls = [];
  this.EventHalls = undefined;
  this.SelectedHalls = undefined;
  this.validStartTime = undefined;
  this.validEndTime = undefined;
  this.validReserveTime = true;
  this.isTenant = undefined;
  this.isWeekend = undefined;
  this.msgError = undefined;
};

ValidReservation.prototype.setHalls = function () {
  this.Halls = this.SelectedHalls.reduce((allHall, Hall) => {
    const hall = {
      reserve: true,
      resId: Hall.resourceId,
    };
    if (hall.resId === '6') {
      hall.isDependence = false;
      hall.dependenceHall = [];
    } else if (hall.resId === '10') {
      hall.isDependence = true;
      hall.dependenceHall = ['6', '11'];
    } else if (hall.resId === '11') {
      hall.isDependence = true;
      hall.dependenceHall = ['6', '10'];
    } else {
      hall.isDependence = true;
      hall.dependenceHall = ['6'];
    }
    return [...allHall, hall];
  }, []);
};

ValidReservation.prototype.setHallOnTenant = function () {
  this.Halls = this.Halls.reduce((allHall, Hall) => {
    Hall.isDependence = false;
    Hall.dependenceHall = [];
    return [...allHall, Hall];
  }, []);
};

ValidReservation.prototype.setHallOnWeekend = function () {
  this.Halls = this.Halls.reduce((allHall, Hall) => {
    if (this.isTenant) {
      Hall.isDependence = true;
      if (Hall.resId === '6') {
        Hall.isDependence = false;
        Hall.dependenceHall = [];
      } else if (Hall.resId === '10') {
        Hall.dependenceHall = ['6', '11'];
      } else if (Hall.resId === '11') {
        Hall.dependenceHall = ['6', '10'];
      } else {
        Hall.isDependence = true;
        Hall.dependenceHall = ['6'];
      }
    }
    return [...allHall, Hall];
  }, []);
};

ValidReservation.prototype.setHallOnReservedData = function () {
  this.Halls = this.Halls.reduce((allHall, Hall) => {
    if (Hall.resId === '6' && !!this.getReserveState(['7', '8', '9'], this.memberNo).cross) {
      Hall.reserve = false;
      this.msgError = this.$vue.$t('alertMessage.invalidVenueMatchTime');
    }
    if ((Hall.resId === '10' || Hall.resId === '11')) {
      const state = this.getReserveState(['6'], this.memberNo);
      if (state.equ || state.cross || state.diff) {
        Hall.isDependence = false;
        Hall.dependenceHall = [];
      }
    }
    if ((Hall.resId === '7' || Hall.resId === '8' || Hall.resId === '9')
    && !!this.getReserveState(['6'], this.memberNo).cross) {
      Hall.reserve = false;
      this.msgError = this.$vue.$t('alertMessage.invalidVenueMatchTime');
    }

    return [...allHall, Hall];
  }, []);
};

ValidReservation.prototype.setReserveTime = function () {
  if (this.SelectedHalls.length) {
    this.validStartTime = this.SelectedHalls[0].start;
    this.validEndTime = this.SelectedHalls[0].end;

    this.SelectedHalls.some((v) => {
      if (v.start !== this.validStartTime || v.end !== this.validEndTime) {
        this.validReserveTime = false;
        return true;
      }
    });
  }
};

ValidReservation.prototype.setTenant = function () {
  const userInfo = this.$vue.$store.getters.getUserInfo();
  this.memberNo = userInfo.member_no;
  this.isTenant = userInfo.member_role === 'MB202' || !!userInfo.external_company_no;
};

ValidReservation.prototype.setWeekend = function () {
  this.isWeekend = (this.$vue.$moment(this.$vue.venueDate).day() === 6);
};

ValidReservation.prototype.setVariousTypesHalls = function () {
  // 입주사일 경우 룸셋팅
  this.isTenant && this.setHallOnTenant();
  // 토요일 경우 룸셋팅
  this.isWeekend && this.setHallOnWeekend();
  // 기존예약되었던 데이타와 조인해 룸셋팅
  this.setHallOnReservedData();
};

/**
 * 예약현황에서 예약시간에 걸치는 방정보(동일, 걸침, 다름)
 * @param {Array} 예약현황에서 검색할 룸([CT201, CT202...])
 * @param {nummber} 예약현황에서 검색할 membmer_no
 * @return {Object} - {equ:0, cross:0, diff:0}
 */

ValidReservation.prototype.getReserveState = function (rCodes, memberNo) {
  let Result = [];
  const _Result = [];
    const Codes = rCodes.length === 0 ? ['6', '7', '8', '9', '10', '11'] : rCodes;

  for (let no = 0; no < Codes.length; no++) {
    this.EventHalls[Codes[no]].forEach((v) => {
      if (v.status === 'CT401' && (!memberNo || (memberNo && memberNo === v.memberId))) {
        _Result.push(v);
      }
    });
  }
  Result = _Result.reduce((allItem, item) => {
    let iStart;
    let iEnd;
    const validStartTime = this.$vue.$moment(this.validStartTime).format('HHmmss');
    const validEndTime = this.$vue.$moment(this.validEndTime).format('HHmmss');

    if (item.resourceId === '6') { // 이벤트 홀 경우 예약 전후 30분이 추가 되어...(데이타는 origin 참조함)
      iStart = `${item.origin.split(' - ')[0].replace(/:/g, '')}00`;
      iEnd = `${item.origin.split(' - ')[1].replace(/:/g, '')}00`;
    } else {
      iStart = item.start.split(' ')[1].replace(/:/g, '');
      iEnd = item.end.split(' ')[1].replace(/:/g, '');
    }

    if (validStartTime === iStart && validEndTime === iEnd) {
      allItem.equ++;
    } else if (validStartTime < iEnd && validEndTime > iStart) {
      allItem.cross++;
    } else {
      allItem.diff++;
    }
    return allItem;
  }, { equ: 0, cross: 0, diff: 0 });
  return Result;
};

ValidReservation.prototype.inValidate = function () { // 무효
  this.setVariousTypesHalls();


  if (this.Halls.length === 0) {
    this.msgError = this.$vue.$t('alertMessage.emptiedVenue');
    return true;
  }
  if (!this.validReserveTime) {
    this.msgError = this.$vue.$t('alertMessage.invalidVenueDiffHour');
    return true;
  }

  return this.Halls.some((v) => {
    if (!v.reserve) {
      return true;
    }

    if (v.isDependence) {
      return !v.dependenceHall.some((v2) => // 의존성체크
        this._inHalls(v2),
      );
    }
  });
};

/**
 * 드래그한 대실에서 대실의 유무를 체크함
 * @param {String} 예약현황에서 검색할 룸([CT201, CT202...])
 * @return {Boolean}
 */
ValidReservation.prototype._inHalls = function (hall) {
  return this.Halls.some((v) => {
    const stateOnSelf = this.getReserveState([hall], this.memberNo);
    if (v.resId === hall || !!stateOnSelf.equ) {
      return true;
    }

    if (!stateOnSelf.cross && this.getReserveState([hall]).cross) {
      hall === '6' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueErr1'));
      hall === '10' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueErr2'));
      hall === '11' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueErr3'));
    } else if (this.isWeekend) {
      hall === '6' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueWeekendRoom'));
      hall === '10' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueWeekendRole1'));
      hall === '11' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueWeekendRole2'));
    } else {
      hall === '6' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueRoom'));
      hall === '10' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueRole1'));
      hall === '11' && (this.msgError = this.$vue.$t('alertMessage.invalidVenueRole2'));
    }
  });
};
