<template>
  <div>
    <!--
      @slot header
        @binding {function} prev 이전 월로 이동
        @binding {function} next 다음 월로 이동
    -->
    <slot name="header"
      :prev="prev"
      :next="next"
    ></slot>
    <slot name="adding"></slot>
    <FullCalendar
      ref="calendar"
      schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
      defaultView="resourceTimeGridDay"
      :locales="locales"
      :editable="false"
      :selectable="true"
      :minTime="minTime"
      :maxTime="maxTime"
      :longPressDelay="longPressDelay"
      :eventTimeFormat="eventTimeFormat"
      :allDaySlot="false"
      :weekends="true"
      :resources="resources"
      :plugins="calendarPlugins"
      :header="header"
      :events="events"
      :datesRender="onDatesRender"
      :unselectAuto="false"
      :validRange="validRange"
      @eventRender="eventRender"
      @select="select"
    ></FullCalendar>
    <!-- :validRange="validRange" -->
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'; // https://fullcalendar.io/docs/vue
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'; //
import interactionPlugin from '@fullcalendar/interaction';
import koLocale from '@fullcalendar/core/locales/ko';
import esLocale from '@fullcalendar/core/locales/en-gb';

/**
 * 월 Calendar 컴포넌트 (시간 단위 그리드, timeGridDay)
 * @see https://fullcalendar.io/docs/timegrid-view
 */
export default {
  name: 'TimeGridDayCalendar',
  components: {
    FullCalendar,
  },
  props: {
    viewDate: {
      type: [Date, String],
      default() {
        return new Date();
      },
    },
    minTime: {
      type: [String],
      default: '09:00:00',
    },
    maxTime: {
      type: [String],
      default: '21:00::00',
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    resources: {
      type: Array,
      default() {
        return [];
      },
    },
    validRange: {
      type: Object,
      default() {
        return {};
      },
    },

  },
  data() {
    return {
      plugins: [resourceTimeGridPlugin, interactionPlugin],
      locale: this.$i18n.locale, // https://github.com/fullcalendar/fullcalendar/issues/5439
      contentHeight: 'auto',
      header: { left: '', center: '', right: '' },
      defaultView: 'resourceTimeGridDay',
      allDaySlot: false,
      longPressDelay: '1000',
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },
      calendarPlugins: [resourceTimeGridPlugin, interactionPlugin],
    };
  },
  watch: {
    viewDate(newViewDate) {
      this.$refs.calendar.getApi().gotoDate(newViewDate);
    },
  },
  computed: {
    viewRangeTitle() {
      return this.$moment(this.viewDate).format(this.$i18n.locale === 'en' ? 'DD.MM.YYYY' : 'YYYY.MM.DD');
    },
  },
  methods: {
    prev() {
      this.$refs.calendar.getApi().prev();
    },
    next() {
      this.$refs.calendar.getApi().next();
    },
    /**
     * @see https://fullcalendar.io/docs/datesRender
     */
    onDatesRender() {
      /**
       * 표시되는 기간 (월) 변경시
       * @property {date} 변경된 기준일자
       */
      const newDate = this.$refs.calendar.getApi().getDate();
      // eslint-disable-next-line eqeqeq
      if (!this.oldDate || (this.oldDate.toString() !== newDate.toString())) {
        this.$emit('change', newDate);
      }
      this.oldDate = newDate;
    },
    eventRender(Info) {
      this.$emit('eventRender', Info);
    },
    select(selectedInfo) {
      this.$emit('select', selectedInfo, this.$refs.calendar);
    },
  },
};
</script>

<style scoped>
@import '~@fullcalendar/core/main.min.css';
@import '~@fullcalendar/daygrid/main.min.css';
@import '~@fullcalendar/timegrid/main.min.css';
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<TimeGridDayCalendar></TimeGridDayCalendar>
```

Header (Title slot, Button slot, adding slot) 사용 예시 :
```vue
<template>
  <TimeGridDayCalendar
    :viewDate="viewDate"
    :events="events"
    :resources="resources"
    :minTime="minTime"
    :maxTime="maxTime"
    @eventRender="onRender"
    @change="onChange"
    @select="onSelect"
    >
    <template v-slot:header="slotProps">
      <div class="calendar-header-area">
        <BaseButton type="prev" @click="slotProps.prev">PREV</BaseButton>
        <div class="month">
          {{ viewRangeTitle }}
          <span class="icon-calendar"></span>
        </div>
        <BaseButton type="next" @click="slotProps.next">NEXT</BaseButton>
      </div>
    </template>
  </TimeGridDayCalendar>
</template>

<script>
export default {
  data() {
    return {
      viewDate: this.$moment().toDate(),
      minTime: '10:00:00',
      maxTime: '23:00::00',
      resources: [
        { id: '6', title: '메인홀' },
        { id: '7', title: '멀티룸A' },
        { id: '8', title: '멀티룸B' },
        { id: '9', title: '멀티룸C' },
        { id: '10', title: '멀티룸D' },
        { id: '11', title: '멀티룸E' },
      ],
      events: [
        {
          id: '6',
          resourceId: '6',
          // title: 'Meeting',
          start: '2020-07-23T11:00:00Z',
          end: '2020-07-23T13:00:00Z',
          className: 'r-waiting',
        },
        {
          id: '2',
          resourceId: '6',
          // title: 'Meeting',
          start: '2020-07-23T17:00:00',
          end: '2020-07-23T20:00:00',
          className: 'r-confirm',
        },
        {
          id: '3',
          resourceId: '8',
          // title: 'Meeting',
          start: '2020-07-23T13:00:00',
          end: '2020-07-23T14:00:00',
          className: 'r-impossible',
        },
      ],
    };
  },
  computed: {
    viewRangeTitle() {
      return this.$moment(this.viewDate).format(this.$i18n.locale === 'en' ? 'DD.MM.YYYY' : 'YYYY.MM.DD');
    },
  },
  methods: {
    onChange(newViewDate) {
      this.viewDate = newViewDate
    },
    onSelect(Info) {
      console.log('==== selected ====')
    },
    /**
     * https://fullcalendar.io/docs/eventRender
     */
    onRender(Info) {
      console.log('==== render ====')
    },
  },
}
</script>

<style scoped>
.calendar-header-area {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-header-area .month {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color:#111;
  padding:0 24px 0 30px;
}
</style>
```
</docs>
